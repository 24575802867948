import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import EmailMarketingWidgetCard from 'components/integrations/EmailMarketingWidgetCard'
import GitHubWidgetCard from 'components/integrations/GitHubWidgetCard'
import HubSpotWidgetCard from 'components/integrations/HubSpotWidgetCard'
import JiraCloudWidgetCard from 'components/integrations/JiraCloudWidgetCard'
import JiraServerWidgetCard from 'components/integrations/JiraServerWidgetCard'
import SalesforceWidgetCard from 'components/integrations/SalesforceWidgetCard'
import ShopifyV2WidgetCard from 'components/integrations/ShopifyV2WidgetCard'
import StripeWidgetCard from 'components/integrations/StripeWidgetCard'
import TrelloWidgetCard from 'components/integrations/TrelloWidgetCard'
import RechargeWidgetCard from 'components/integrations/RechargeWidgetCard'
import { selectCurrentContactId } from 'ducks/crm/contacts'
import { selectCurrentCustomerId } from 'selectors/tickets/customer/selectCurrentCustomerId'
import { CUSTOM_FIELD_CATEGORY_DISPLAY_EXCLUDE_KEYS } from 'ducks/crm/customFieldCategories/constants'

import ChatInsightsWidgetCard from './cards/ChatInsights'
import CustomFieldCategory from './cards/CustomFieldCategory'
import CustomProfileWidgetCard from './cards/CustomProfile'
import BrowserInfoWidgetCard from './cards/BrowserInfo'
import RecentConversationsWidgetCard from './cards/RecentConversations'
import VisitHistoryWidgetCard from './cards/VisitHistory'
import AttachmentsCard from './cards/Attachments'
import AiCard from './cards/Ai'

const EMAIL_MARKETING_INTEGRATIONS = [
  'MAILCHIMP',
  'CONSTANT_CONTACT',
  'CAMPAIGN_MONITOR',
]
const INTEGRATION_COMPONENTS = {
  JIRA_CLOUD: JiraCloudWidgetCard,
  GITHUB: GitHubWidgetCard,
  HUBSPOT: HubSpotWidgetCard,
  JIRA_SERVER: JiraServerWidgetCard,
  SALESFORCE: SalesforceWidgetCard,
  SHOPIFY_V2: ShopifyV2WidgetCard,
  STRIPE: StripeWidgetCard,
  TRELLO: TrelloWidgetCard,
  RECHARGE: RechargeWidgetCard,
}

const CUSTOM_WIDGET_COMPONENTS = {
  browser_info: BrowserInfoWidgetCard,
  chat_insights: ChatInsightsWidgetCard,
  custom_profile: CustomProfileWidgetCard,
  recent_conversations: RecentConversationsWidgetCard,
  attachments: AttachmentsCard,
  ai: AiCard,
  visit_history: VisitHistoryWidgetCard,
}

export default function WidgetCard({ doHideCard, reorderable, widgetCard }) {
  const {
    id,
    key,
    customWidget,
    name,
    provider,
    type: categoryType,
    uiKey,
  } = widgetCard
  const customerId = useSelector(selectCurrentCustomerId)
  const contactId = useSelector(selectCurrentContactId)
  const boundDoHideCard = useCallback(() => doHideCard(uiKey), [
    doHideCard,
    uiKey,
  ])

  if (customWidget) {
    const CustomWidgetComponent = CUSTOM_WIDGET_COMPONENTS[id]
    return (
      <div className="WidgetCard-wrapper">
        <CustomWidgetComponent
          doHideCard={boundDoHideCard}
          hideable={!!doHideCard}
          key={id}
        />
      </div>
    )
  }
  const IntegrationComponent = INTEGRATION_COMPONENTS[provider]
  if (provider) {
    if (IntegrationComponent)
      return (
        <div className="WidgetCard-wrapper">
          <IntegrationComponent
            contactId={contactId}
            customerId={customerId}
            doHideCard={boundDoHideCard}
            hideable={!!doHideCard}
            id={id}
            key={id}
            name={name}
          />
        </div>
      )
    return null
  }
  if (EMAIL_MARKETING_INTEGRATIONS.includes(categoryType)) {
    return (
      <div className="WidgetCard-wrapper">
        <EmailMarketingWidgetCard
          key={categoryType}
          doHideItem={boundDoHideCard}
          contactId={contactId}
          {...widgetCard}
        />
      </div>
    )
  }

  if (!CUSTOM_FIELD_CATEGORY_DISPLAY_EXCLUDE_KEYS.includes(key)) {
    return (
      <div className="WidgetCard-wrapper">
        <CustomFieldCategory
          key={id}
          doHideCard={boundDoHideCard}
          categoryId={id}
          categoryKey={key}
          categoryName={name}
          categoryType={categoryType}
          hideable={!!doHideCard}
          reorderable={reorderable}
        />
      </div>
    )
  }
  return null
}
